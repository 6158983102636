// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-custom, .bg-dark {
    background-color: #4B0082 !important;
}

.nav-link {
    margin-left: 32px;
}

.toggle {
    margin-right: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar/styles.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".navbar-custom, .bg-dark {\n    background-color: #4B0082 !important;\n}\n\n.nav-link {\n    margin-left: 32px;\n}\n\n.toggle {\n    margin-right: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
