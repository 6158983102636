// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bingo-card > thead > tr > th {
    width: 50px;
    text-align: center;
    font-size: x-large;
}

.bingo-button {
    width: 50px;
    height: 50px;
}

.bingo-container {
    max-width: 600px;
}

body {
    height: 100vh;
}

#root {
    height: 100vh;
}


.btn-danger-stop:disabled,
.btn-danger-stop[disabled]{
  background-color: #333;
  color: #c0c0c0;
}
`, "",{"version":3,"sources":["webpack://./src/components/game/styles.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;;AAGA;;EAEE,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":["\n.bingo-card > thead > tr > th {\n    width: 50px;\n    text-align: center;\n    font-size: x-large;\n}\n\n.bingo-button {\n    width: 50px;\n    height: 50px;\n}\n\n.bingo-container {\n    max-width: 600px;\n}\n\nbody {\n    height: 100vh;\n}\n\n#root {\n    height: 100vh;\n}\n\n\n.btn-danger-stop:disabled,\n.btn-danger-stop[disabled]{\n  background-color: #333;\n  color: #c0c0c0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
