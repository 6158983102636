import React from "react";
import NavBar from "../../components/NavBar";

const Winner = () => {

    return (
        <>
            <div className="container d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                Parabéns! Você ganhou
            </div>
        </>
    )
}

export default Winner;
